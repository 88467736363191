import React from 'react'

import { Layout, Navbar } from '../Layout'
import { PrivacyPolicy } from '../components/PrivacyPolicy'

const PrivacyPolicyPage = () => (
	<Layout
		title="Privacy Policy"
		navbar={<Navbar position="absolute"/>}
		content={<PrivacyPolicy/>}
		footer={null}
	/>
)

export default PrivacyPolicyPage