import React from 'react'
import styled from 'styled-components'

import { Typography } from '../components/primitives'

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 4em 2em;
  line-break: loose;

  a {
    color: ${({ theme }) => theme.text};
  }
`

export const PrivacyPolicy = () => {
	return (
		<Wrapper className="w-full md:w-1/2">
			<Typography variant="h2" weight="bold">
        Privacy Policy for Utter
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        At Utter.fun, accessible from https://utter.fun/, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by
        Utter.fun and how we use it.
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in Utter.fun. This policy is not applicable to any
        information collected offline or via channels other than this website.
        Our Privacy Policy was created with the help of the{' '}
				<a href="https://www.privacypolicygenerator.org/">
          Free Privacy Policy Generator
				</a>
			</Typography>

			<Typography variant="h4" weight="bold">
        Consent
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
			</Typography>

			<Typography variant="h4" weight="bold">
        Information we collect
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask you to provide your personal information.
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        If you contact us directly, we may receive additional information about
        you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information
        you may choose to provide.
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        When you register for an Account, we may ask for your contact
        information, including items such as name, company name, address, email
        address, and telephone number.
			</Typography>

			<Typography variant="h4" weight="bold">
        How we use your information
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        We use the information we collect in various ways, including to:
			</Typography>

			<ul>
				<Typography variant="p" className="flex flex-col my-4" weight="light">
					<li>Provide, operate, and maintain our website</li>
				</Typography>
				<Typography variant="p" className="flex flex-col my-4" weight="light">
					<li>Improve, personalize, and expand our website</li>
				</Typography>
				<Typography variant="p" className="flex flex-col my-4" weight="light">
					<li>Understand and analyze how you use our website</li>
				</Typography>
				<Typography variant="p" className="flex flex-col my-4" weight="light">
					<li>Develop new products, services, features, and functionality</li>
				</Typography>
				<Typography variant="p" className="flex flex-col my-4" weight="light">
					<li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes
					</li>
				</Typography>
				<Typography variant="p" className="flex flex-col my-4" weight="light">
					<li>Send you emails</li>
				</Typography>
				<Typography variant="p" className="flex flex-col my-4" weight="light">
					<li>Find and prevent fraud</li>
				</Typography>
			</ul>

			<Typography variant="h4" weight="bold">
        Log Files
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        Utter.fun follows a standard procedure of using log files. These files
        log visitors when they visit websites. All hosting companies do this and
        a part of hosting services&apos; analytics. The information collected by log
        files include internet protocol (IP) addresses, browser type, Internet
        Service Provider (ISP), date and time stamp, referring/exit pages, and
        possibly the number of clicks. These are not linked to any information
        that is personally identifiable. The purpose of the information is for
        analyzing trends, administering the site, tracking users&apos; movement on
        the website, and gathering demographic information.
			</Typography>

			<Typography variant="h4" weight="bold">
        Cookies and Web Beacons
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        Like any other website, Utter.fun uses &apos;cookies&apos;. These cookies are used
        to store information including visitors&apos; preferences, and the pages on
        the website that the visitor accessed or visited. The information is
        used to optimize the users&apos; experience by customizing our web page
        content based on visitors&apos; browser type and/or other information.
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        For more general information on cookies, please read{' '}
				<a href="https://www.privacypolicyonline.com/what-are-cookies/">
          "What Are Cookies" from Cookie Consent
				</a>
        .
			</Typography>

			<Typography variant="h4" weight="bold">
        Advertising Partners Privacy Policies
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        You may consult this list to find the Privacy Policy for each of the
        advertising partners of Utter.fun.
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        Third-party ad servers or ad networks uses technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on Utter.fun, which are sent
        directly to users&apos; browser. They automatically receive your IP address
        when this occurs. These technologies are used to measure the
        effectiveness of their advertising campaigns and/or to personalize the
        advertising content that you see on websites that you visit.
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        Note that Utter.fun has no access to or control over these cookies that
        are used by third-party advertisers.
			</Typography>

			<Typography variant="h4" weight="bold">
        Third Party Privacy Policies
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        Utter.fun&apos;s Privacy Policy does not apply to other advertisers or
        websites. Thus, we are advising you to consult the respective Privacy
        Policies of these third-party ad servers for more detailed information.
        It may include their practices and instructions about how to opt-out of
        certain options.{' '}
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers&apos; respective
        websites.
			</Typography>

			<Typography variant="h4" weight="bold">
        CCPA Privacy Rights (Do Not Sell My Personal Information)
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        Under the CCPA, among other rights, California consumers have the right
        to:
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        Request that a business that collects a consumer&apos;s personal data
        disclose the categories and specific pieces of personal data that a
        business has collected about consumers.
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        Request that a business delete any personal data about the consumer that
        a business has collected.
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        Request that a business that sells a consumer&apos;s personal data, not sell
        the consumer&apos;s personal data.
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
			</Typography>

			<Typography variant="h4" weight="bold">
        GDPR Data Protection Rights
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        We would like to make sure you are fully aware of all of your data
        protection rights. Every user is entitled to the following:
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        The right to access – You have the right to request copies of your
        personal data. We may charge you a small fee for this service.
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        The right to rectification – You have the right to request that we
        correct any information you believe is inaccurate. You also have the
        right to request that we complete the information you believe is
        incomplete.
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        The right to erasure – You have the right to request that we erase your
        personal data, under certain conditions.
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        The right to restrict processing – You have the right to request that we
        restrict the processing of your personal data, under certain conditions.
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        The right to object to processing – You have the right to object to our
        processing of your personal data, under certain conditions.
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        The right to data portability – You have the right to request that we
        transfer the data that we have collected to another organization, or
        directly to you, under certain conditions.
			</Typography>
			<Typography variant="p" className="flex flex-col my-4" weight="light">
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us.
			</Typography>

			<Typography variant="h4" weight="bold">
        Children&apos;s Information
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
			</Typography>

			<Typography variant="p" className="flex flex-col my-4" weight="light">
        Utter.fun does not knowingly collect any Personal Identifiable
        Information from children under the age of 13. If you think that your
        child provided this kind of information on our website, we strongly
        encourage you to contact us immediately and we will do our best efforts
        to promptly remove such information from our records.
			</Typography>
		</Wrapper>
	)
}
